import { pickBy, isArray } from 'lodash'
import { useRoute } from 'vue-router'
import moment from 'moment'

const parseArray = (value) => value.map(item => (!isNaN(item) ? Number(item) : item))
const parseDate = (value) => moment(value, moment.ISO_8601, true).isValid() ? moment(value) : value

// Detect and cast to the correct type
const parseValue = (value, key) => {
  if (key.includes('date')) return parseDate(value)
  if (Array.isArray(value)) return parseArray(value)
  if (typeof value === 'string') {
    if (value.includes(',')) return parseArray(value.split(','))
    if (!isNaN(value.trim())) return Number(value) // Only convert non-empty valid numbers
  }
  return value
}

// Convert moment objects and arrays to URL-friendly strings
const formatValueForUrl = (value) => {
  if (moment.isMoment(value)) return value.format('YYYY-MM-DD')
  if (Array.isArray(value)) return value.join(',')
  return value
}

// Get the hash part of the URL without query params
const getHashWithoutQueryParams = () => window.location.hash.split('?')[0]

// Update query params dynamically
const updateUrlWithQuery = (params) => {
  const cleanHash = getHashWithoutQueryParams()

  const filteredQuery = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== null && value !== undefined && value !== ''),
  )

  const formattedQuery = Object.fromEntries(
    Object.entries(filteredQuery).map(([key, value]) => [key, formatValueForUrl(value)]),
  )

  const newUrl = `${cleanHash}${Object.keys(formattedQuery).length ? '?' + new URLSearchParams(formattedQuery).toString() : ''}`

  window.history.replaceState(null, '', newUrl)

  return pickBy(filteredQuery, (value) => {
    return value !== null && 
      value !== undefined && 
      value !== '' && 
      (!isArray(value) || value.length > 0)
  })
}

// Extract all query params, supporting default params
const extractQueryParams = (defaultQueryParams = {}) => {
  const route = useRoute()
  const query = route.query

  return Object.keys({ ...defaultQueryParams, ...query }).reduce((acc, key) => {
    const queryValue = query[key]

    // Only parse if value exists in query, otherwise use default
    acc[key] = queryValue ? parseValue(queryValue, key) : defaultQueryParams[key]
    return acc
  }, {})
}

export {
  updateUrlWithQuery,
  extractQueryParams,
}
