import toNumber from 'lodash/toNumber'
import moment from 'moment'

const Base64Encode = (val) => {
    return btoa(val)
}

const FormatNumber = (val) => {
    if (val == null) return '0'
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}


// https://stackoverflow.com/a/61994179
const FormatCurrency = (number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') => {
    // Returns the default format if the input is not valid
    if (number === null || number === undefined || number === '') {
        return `0${decimalSeparator}${'0'.repeat(decimals)}`
    }

    // Sanitize inputs and convert to space
    const sanitizedNumber = parseFloat(String(number).replace(/[^0-9.-]/g, ''))
    if (isNaN(sanitizedNumber)) throw new Error("The value given is not a valiable number.")

    // Number format with decimals
    const [integerPart, fractionalPart = ''] = sanitizedNumber.toFixed(decimals).split('.')

    // Add ribua separator
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)

    return `${formattedInteger}${decimals ? decimalSeparator + fractionalPart : ''}`
}

const DateFormat = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null
}

const DateFormatMonth = (date) => {
    return date ? moment(date).format('YYYY-MM') : null
}

const FormatPercentage = (value, decimals = 2) => {
  // Convert the value to a decimal number according to the desired amount
  const num = parseFloat(value)
  if (isNaN(num)) {
    throw new Error("The value given is not a valiable number.")
  }
  // Returns a value with the specified decimal
  return num.toFixed(decimals)
}

export {
    Base64Encode,
    FormatNumber,
    FormatCurrency,
    DateFormat,
    DateFormatMonth,
    FormatPercentage,
}